import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { FieldArray, useFormikContext, validateYupSchema } from 'formik';
import {
  DatePicker,
  MaskedInput as Input,
  FileInput,
  Button,
  Icons,
  ConnectedSelect,
  Section,
} from '@casanova/casanova-common';
import i18n from '@i18n';
import { alphaMask, alphaMaskWithSpacesAndAccent } from 'utils/masks';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { EXTERNAL_LINKS } from 'utils/constants/externalLinks';
import { acceptFiles } from 'utils/files';
import { transformIdOfList } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { COUNTRIES } from 'utils/constants/countries';
import { driverSchema, curpDriverSchema } from './schema';
import {
  defaultDriverData,
  initialValues as driversInitialValues,
  initialTouched as driversInitialTouched,
} from './config';

const DriversAuthorized = ({
  validateCurpDriver,
  cleanValidateCurpDriver,
  driverCurpInfo,
  action,
  setFieldsTouched,
}) => {
  const [shouldDisable, setShouldDisable] = useState(
    action === FORM_ACTIONS.EDIT
  );
  const [driverIndex, setDriverIndex] = useState(0);

  const {
    initialValues,
    values,
    errors,
    touched,
    setErrors,
    setValues,
    setFieldValue,
    setTouched,
    setFieldTouched,
  } = useFormikContext();
  const { driverAuthorizedData = [] } = values;
  const { isValidating, isValid, data } = driverCurpInfo;

  const handleValidateCurpDriver = useCallback(
    (driverCurp, index) => {
      setDriverIndex(index);

      if (errors.driverAuthorizedData) {
        const driverError = errors.driverAuthorizedData[index];
        if (driverError && driverError.driverCurp) {
          setFieldTouched(`driverAuthorizedData[${index}].driverCurp`, true);
          return;
        }
      }

      validateYupSchema({ driverCurp }, curpDriverSchema({ parent: values }))
        .then(() => {
          validateCurpDriver(driverCurp);
        })
        .catch((errorsInner) => {
          setErrors({ driverCurp: errorsInner.errors[0] });
        });
    },
    [
      validateCurpDriver,
      driverIndex,
      setErrors,
      values,
      errors,
      setFieldTouched,
    ]
  );

  const handleAddDriver = useCallback(
    ({ push }) => {
      const index = driverAuthorizedData.length - 1;
      const lastDriver = driverAuthorizedData[index];
      const someFieldWithValue = Object.values(lastDriver).some(
        (value) => value
      );

      if (lastDriver && someFieldWithValue) {
        validateYupSchema(lastDriver, driverSchema({ parent: values }))
          .then(() => {
            push(defaultDriverData[0]);
            setFieldsTouched(
              ({
                driverAuthorizedData: driverAuthorizedDataTouched = [],
                ...currentFieldsTouched
              }) => ({
                ...currentFieldsTouched,
                driverAuthorizedData: driverAuthorizedDataTouched.concat(
                  driversInitialTouched.driverAuthorizedData
                ),
              })
            );
          })
          .catch(() => {
            const driversTouched = Object.entries(lastDriver).reduce(
              (acc, [key]) => {
                acc[key] = true;
                return acc;
              },
              {}
            );

            const driversData = [...driverAuthorizedData];
            driversData[index] = driversTouched;
            setTouched({ ...touched, driverAuthorizedData: driversData });
          });
      }
    },
    [driverAuthorizedData, touched, setTouched, values, setFieldsTouched]
  );

  const handleRemove = useCallback(
    ({ remove }, index) => {
      if (driverAuthorizedData.length > 1) {
        remove(index);
        setFieldsTouched(
          ({
            driverAuthorizedData: driverAuthorizedDataTouched = [],
            ...currentFieldsTouched
          }) => ({
            ...currentFieldsTouched,
            driverAuthorizedData: driverAuthorizedDataTouched.filter(
              (_driver, i) => i !== index
            ),
          })
        );
      } else {
        setValues({ ...values, ...driversInitialValues });
      }
    },
    [setValues, values, setFieldsTouched]
  );

  useEffect(() => {
    const exists = !isValidating && isValid && data;

    if (exists) {
      const {
        names,
        lastName,
        secondLastName,
        sex,
        birthState,
        birthDate,
        birthCountry,
      } = data;
      setFieldValue(`driverAuthorizedData[${driverIndex}].driverName`, names);
      setFieldValue(
        `driverAuthorizedData[${driverIndex}].driverFirstName`,
        lastName
      );
      setFieldValue(
        `driverAuthorizedData[${driverIndex}].driverLastName`,
        secondLastName
      );
      setFieldValue(
        `driverAuthorizedData[${driverIndex}].driverBirthDate`,
        birthDate
      );
      setFieldValue(
        `driverAuthorizedData[${driverIndex}].driverBirthState`,
        _get(birthState, 'name', '')
      );
      setFieldValue(
        `driverAuthorizedData[${driverIndex}].driverBirthCountry`,
        birthCountry
      );
      setFieldValue(
        `driverAuthorizedData[${driverIndex}].driverGender`,
        _get(sex, 'name', '')
      );
      setShouldDisable(true);
      cleanValidateCurpDriver();
    }

    if (!isValidating && !isValid && values.driverCurp) {
      setValues({ ...values, ...initialValues });
      setShouldDisable(false);
      setErrors({ driverCurp: i18n('ERROR__CUSTOMERS__CURP__INVALID') });
    }
  }, [
    driverCurpInfo,
    setFieldValue,
    driverIndex,
    setValues,
    cleanValidateCurpDriver,
  ]);

  useEffect(
    () => () => {
      cleanValidateCurpDriver();
    },
    [cleanValidateCurpDriver]
  );

  const showUrl = action !== FORM_ACTIONS.VIEW;
  const disabledInput = action !== FORM_ACTIONS.ADD;
  return (
    <Section title="CHOFERES AUTORIZADOS">
      <FieldArray name="driverAuthorizedData">
        {(arrayHelpers) => (
          <>
            {driverAuthorizedData &&
              transformIdOfList(driverAuthorizedData).map(
                (
                  {
                    id,
                    driverCurp,
                    driverBirthDate,
                    driverBirthCountry,
                    driverFrontLicense,
                    driverFrontLicenseUrl,
                    driverBackLicense,
                    driverBackLicenseUrl,
                    driverBirthState,
                  },
                  index
                ) => {
                  const hasForeign = driverBirthCountry !== COUNTRIES.MEXICO;

                  return (
                    <Fragment key={id}>
                      <div className="row">
                        <div className="col-4">
                          <Input
                            mask={alphaMask(18)}
                            maskPlaceholder=""
                            link={EXTERNAL_LINKS.GOB_CURP}
                            linkText="Consultar CURP"
                            label={`CURP${!initialValues.isAval ? '*' : ''}`}
                            placeholder={`CURP${
                              !initialValues.isAval ? '*' : ''
                            }`}
                            name={`driverAuthorizedData[${index}].driverCurp`}
                            upperCase
                          />
                        </div>
                        <div className="col-2 pt-2">
                          <br />
                          <Button
                            block
                            onClick={() => {
                              handleValidateCurpDriver(driverCurp, index);
                            }}
                          >
                            {i18n('CUSTOMERS__CURP__SEARCH_BUTTON')}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <Input
                            label="NOMBRE (S)"
                            placeholder="NOMBRE (S)"
                            name={`driverAuthorizedData[${index}].driverName`}
                            disabled
                            mask={alphaMaskWithSpacesAndAccent(50)}
                            maskPlaceholder=""
                          />
                        </div>
                        <div className="col-4">
                          <Input
                            label="PRIMER APELLIDO"
                            placeholder="PRIMER APELLIDO"
                            name={`driverAuthorizedData[${index}].driverFirstName`}
                            mask={alphaMaskWithSpacesAndAccent(50)}
                            maskPlaceholder=""
                            disabled
                          />
                        </div>
                        <div className="col-4">
                          <Input
                            label="SEGUNDO APELLIDO"
                            placeholder="SEGUNDO APELLIDO"
                            name={`driverAuthorizedData[${index}].driverLastName`}
                            mask={alphaMaskWithSpacesAndAccent(50)}
                            maskPlaceholder=""
                            disabled
                          />
                        </div>
                        <div className="col-4">
                          <DatePicker
                            initialValue={
                              shouldDisable ? driverBirthDate : undefined
                            }
                            placeholder="FECHA DE NACIMIENTO"
                            name={`driverAuthorizedData[${index}].driverBirthDate`}
                            label="FECHA DE NACIMIENTO"
                            disabled
                            initialYear={2010}
                            numberYears={72}
                          />
                        </div>
                        <div className="col-4">
                          {!hasForeign ? (
                            <>
                              {driverBirthState ? (
                                <Input
                                  label="LUGAR DE NACIMIENTO"
                                  placeholder="LUGAR DE NACIMIENTO"
                                  name={`driverAuthorizedData[${index}].driverBirthState`}
                                  mask={alphaMaskWithSpacesAndAccent(50)}
                                  maskPlaceholder=""
                                  disabled
                                />
                              ) : (
                                <ConnectedSelect
                                  label="LUGAR DE NACIMIENTO"
                                  name={`driverAuthorizedData[${index}].driverPlaceBirth`}
                                  options="commons.states"
                                  mask={alphaMaskWithSpacesAndAccent(50)}
                                  maskPlaceholder=""
                                  disabled
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {(!driverBirthCountry ||
                                driverAuthorizedData.length <= 3) &&
                              driverBirthState ? (
                                <Input
                                  label="LUGAR DE NACIMIENTO"
                                  placeholder="LUGAR DE NACIMIENTO"
                                  name={`driverAuthorizedData[${index}].driverBirthState`}
                                  disabled
                                />
                              ) : (
                                <Input
                                  label="PAÍS DE NACIMIENTO"
                                  placeholder="PAÍS DE NACIMIENTO"
                                  name={`driverAuthorizedData[${index}].driverBirthCountry`}
                                  mask={alphaMaskWithSpacesAndAccent(50)}
                                  maskPlaceholder=""
                                  disabled
                                />
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-4">
                          {
                            // TODO: Waiting for sex catalog uuid, validate this with Carlos
                            disabledInput ? (
                              <Input
                                label="GENERO"
                                placeholder="GENERO"
                                name={`driverAuthorizedData[${index}].driverGender`}
                                disabled
                              />
                            ) : (
                              <ConnectedSelect
                                label="GENERO"
                                name={`driverAuthorizedData[${index}].driverGender`}
                                options="commons.sexes"
                                disabled
                              />
                            )
                          }
                        </div>

                        <div className="col-4">
                          <FileInput
                            url={showUrl ? driverFrontLicenseUrl : undefined}
                            displayText={driverFrontLicense}
                            type="file"
                            label={`FRONTAL LICENCIA${
                              !initialValues.isAval ? '*' : ''
                            }`}
                            placeholder={`FRONTAL LICENCIA${
                              !initialValues.isAval ? '*' : ''
                            }`}
                            name={`driverAuthorizedData[${index}].driverFrontLicense`}
                            accept={acceptFiles}
                          />
                        </div>
                        <div className="col-4">
                          <FileInput
                            url={showUrl ? driverBackLicenseUrl : undefined}
                            displayText={driverBackLicense}
                            type="file"
                            label={`TRASERA LICENCIA${
                              !initialValues.isAval ? '*' : ''
                            }`}
                            placeholder={`TRASERA LICENCIA${
                              !initialValues.isAval ? '*' : ''
                            }`}
                            name={`driverAuthorizedData[${index}].driverBackLicense`}
                            accept={acceptFiles}
                          />
                        </div>
                        <div className="col-md-4 d-flex align-items-center">
                          {((driverAuthorizedData &&
                            driverAuthorizedData.length > 1) ||
                            driverAuthorizedData[index].driverName) &&
                            !disabledInput && (
                              <span
                                onClick={() =>
                                  handleRemove(arrayHelpers, index)
                                }
                                onKeyPress={() =>
                                  handleRemove(arrayHelpers, index)
                                }
                                className="removable svg-icon svg-icon-center"
                                role="button"
                                tabIndex="0"
                              >
                                <Icons.CloseGroup width="100%" height="100%" />
                              </span>
                            )}
                        </div>
                      </div>
                    </Fragment>
                  );
                }
              )}

            {driverAuthorizedData && driverAuthorizedData.length < 3 && (
              <div className="row">
                <div className="d-flex justify-content-end align-items-center col-12 col-md-4 offset-md-8">
                  <span className="btn-add-span">Agregar chofer adicional</span>
                  <Button
                    onClick={() => handleAddDriver(arrayHelpers)}
                    color="add"
                  >
                    <span className="icon">+</span>
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </FieldArray>
      {errors && errors.globalValidation && (
        <div className="col-form-error text-danger">
          {errors.globalValidation}
        </div>
      )}
    </Section>
  );
};

DriversAuthorized.propTypes = {
  action: PropTypes.string,
};

DriversAuthorized.defaultProps = {
  action: 'add',
};

export default DriversAuthorized;
